/**
 * This is the global configuration file for Production Environment.
 */
export const configuration = {
  applicationName: 'Greeho',
  accountsHost: 'https://accounts.camelot.greeho.com',
  accountsLoginPath: '/login',
  defaultOrigin: 'https://admin.camelot.greeho.com',
  accountsOrigin: 'https://accounts.camelot.greeho.com',
  /** Environment specific configuration for REST API Client. */
  restApiClient: {
    host: 'https://camelot.greeho.com',
  },
  languages: [
    { shortName: 'en', name: 'English', },
    { shortName: 'bn', name: 'Bengali', },
    { shortName: 'ur', name: 'Urdu', },
    { shortName: 'hi', name: 'Hindi', },
    { shortName: 'es', name: 'Spanish', },
    { shortName: 'fr', name: 'French', },
    { shortName: 'ja', name: 'Japanese', },
    { shortName: 'zh', name: 'Chinese', },
    { shortName: 'ar', name: 'Arabic', },
  ],
};
